<template>
  <!-- interweavenote: `navbar` structure with the `TreeCrumb` class -->
  <nav class="navbar treecrumb pull-left nav-open" role="navigation">
    <h2 class="sr-only">Orientation in the website</h2>
    <ul class="nav navbar-nav nav-open">
      <li class="dropdown">
        <a id="admin-dropdown" href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
          ><span class="icon icon--bottom"></span> Der Bundesrat</a
        >
        <ul class="dropdown-menu" role="menu" aria-labelledby="admin-dropdown">
          <!-- Dropdown title, can ben place anywhere in the list -->
          <li class="dropdown-header" role="presentation">
            <a
              href="https://www.admin.ch/gov/de/start.html"
              class="icon icon--after icon--external"
              target="_blank"
              role="menuitem"
              tabindex="-1"
              >Bundesverwaltung</a
            >
            <ul role="menu">
              <li role="presentation">
                <a
                  href="https://www.bk.admin.ch/index.html?lang=de"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Schweizerische Bundeskanzlei (BK)
                </a>
              </li>
              <li role="presentation">
                <a
                  href="https://www.eda.admin.ch/eda/de/home.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Eidgenössisches Departement für auswärtige Angelegenheiten (EDA)</a
                >
              </li>
              <li role="presentation">
                <a
                  href="https://www.edi.admin.ch/edi/de/home.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Eidgenössisches Departement des Innern (EDI)
                </a>
              </li>
              <li role="presentation">
                <a
                  href="http://www.ejpd.admin.ch/ejpd/de/home.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Eidgenössisches Justiz- und Polizeidepartement (EJPD)
                </a>
              </li>
              <li role="presentation">
                <strong
                  ><a href="http://www.vbs.admin.ch/" class="icon icon--after icon--external" target="_blank" role="menuitem" tabindex="-1"
                    >Eidgenössisches Departement für Verteidigung, Bevölkerungsschutz und Sport (VBS)
                  </a></strong
                >
              </li>
              <li role="presentation">
                <a
                  href="https://www.efd.admin.ch/efd/de/home.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Eidgenössisches Finanzdepartement (EFD)
                </a>
              </li>
              <li role="presentation">
                <a
                  href="https://www.wbf.admin.ch/wbf/de/home.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Eidgenössisches Departement für Wirtschaft, Bildung und Forschung (WBF)
                </a>
              </li>
              <li role="presentation">
                <a
                  href="https://www.uvek.admin.ch/uvek/de/home.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Eidgenössisches Departement für Umwelt, Verkehr, Energie und Kommunikation (UVEK)
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li class="dropdown">
        <a href="#" id="service-dropdown" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          ><span class="icon icon--right"></span> Departement: VBS</a
        >
        <ul class="dropdown-menu" role="menu" aria-labelledby="service-dropdown">
          <li class="dropdown-header" role="presentation">
            <a href="http://www.vbs.admin.ch/" class="icon icon--after icon--external" target="_blank" role="menuitem" tabindex="-1"
              >Eidgenössisches Departement für Verteidigung, Bevölkerungsschutz und Sport</a
            >
            <ul role="menu">
              <li role="presentation">
                <a
                  href="http://www.vbs.admin.ch/de/vbs/organisation/verwaltungseinheiten/generalsekretariat.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Generalsekretariat</a
                >
              </li>
              <li role="presentation">
                <a
                  href="http://www.vbs.admin.ch/de/vbs/organisation/verwaltungseinheiten/nachrichtendienst.html"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >Nachrichtendienst des Bundes</a
                >
              </li>
              <li role="presentation">
                <a href="http://www.vtg.admin.ch/" class="icon icon--after icon--external" target="_blank" role="menuitem" tabindex="-1"
                  >Schweizer Armee
                </a>
              </li>
              <li role="presentation">
                <a href="http://www.ar.admin.ch/" class="icon icon--after icon--external" target="_blank" role="menuitem" tabindex="-1"
                  >armasuisse</a
                >
              </li>
              <li role="presentation">
                <a
                  href="http://www.swisstopo.admin.ch/"
                  class="icon icon--after icon--external"
                  target="_blank"
                  role="menuitem"
                  tabindex="-1"
                  >swisstopo</a
                >
              </li>
              <li role="presentation">
                <a href="http://www.baspo.admin.ch/" class="icon icon--after icon--external" target="_blank" role="menuitem" tabindex="-1"
                  >Sport</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="dropdown">
        <router-link to="/" class="dropdown-toggle">ZKDB</router-link>
      </li>
    </ul>
  </nav>
</template>
