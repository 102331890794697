<template>
  <div id="app" @mousemove="mouseMove()">
    <MainZKDB />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import MainZKDB from "@/components/MainZKDB";
import { getUiConfig } from "@/api/ui-config-services";

export default {
  name: "MeinZKDBapp",
  components: {
    MainZKDB,
  },

  data() {
    return {
      isUserActive: false,
      isNewTokenRequired: false,
      newTokenCountdown: null,
      logOutCountdown: null,
      secToTokenNecessityCheck: null,
      secToLogOut: null,
      secToRenewTokenBeforeExpire: 15,
    };
  },

  computed: {
    ...mapGetters(["baseURL", "eiamURL", "eiamClientId"]),

    userName() {
      return this.$store.state.authModule.signInState.benutzerName;
    },
  },

  watch: {
    userName() {
      // Wenn sich ein User einloggt
      if (this.$store.state.authModule.signInState.benutzerName != "" && this.$store.state.authModule.signInState.benutzerName != null) {
        this.startCountdowns();
      } else {
        // Wenn sich ein User ausloggt
        this.destroyCounters();
      }
    },
  },

  async mounted() {
    // UI Config aus Backend laden
    this.$store.commit("setUiConfig", await getUiConfig());
    this.$store.commit("setUserProps");
  },

  methods: {
    ...mapActions("authModule", ["logoutUserAction"]),
    ...mapActions("authModule", ["tokenRefreshAction"]),

    mouseMove() {
      this.isUserActive = true;
    },

    startCountdowns() {
      this.secToLogOut = sessionStorage.getItem("validityDuration");
      this.setNewTokenCountdown();
      this.setLogOutCountdown();
    },

    destroyCounters() {
      clearTimeout(this.newTokenCounter);
      clearTimeout(this.logOutCounter);
    },

    finish() {
      this.destroyCounters();
      this.$store.dispatch(AUTH_LOGOUT);
    },

    setNewTokenCountdown() {
      this.newTokenCounter = setTimeout(() => {
        // Aktuelle UNIX-Zeit
        let unixTimeNow = new Date().getTime();
        // Vergleich der aktuellen Zeit mit der Token-Refresh-Zeit
        if (unixTimeNow > sessionStorage.getItem("tokenRefreshTime")) {
          // Wenn es maximal 5 Sekunden bis zum ablauf des Tokens sind wir der Token refreshed
          let loginParams = {
            eiamLoginParams: {
              grant_type: "refresh_token",
              client_id: "VTG-FUB-zkdb",
              refresh_token: sessionStorage.getItem("refreshToken"),
              scope: "openid",
            },
            tokenEndpoint: this.eiamURL + "realms/vbs_vtg-fub-zkdb/protocol/openid-connect/token",
          };
          this.tokenRefreshAction(loginParams);
        }
        this.setNewTokenCountdown();
      }, 1000);
    },

    setLogOutCountdown() {
      this.logOutCounter = setTimeout(() => {
        // Wenn der Zähler grösser als 0 ist...
        if (this.secToLogOut > 0) {
          // ...und der User die Maus bewegt hat
          if (this.isUserActive) {
            this.isUserActive = false;
            this.secToLogOut = sessionStorage.getItem("validityDuration");
            // ... und der User die Maus nicht betätigt hat
          } else {
            this.secToLogOut--;
          }
          // Wenn der Zähler 0 oder kleiner ist ist
        } else {
          this.destroyCounters();
          this.isNewTokenRequired = false;
          this.secToTokenNecessityCheck = 0;
          let logOutUrls = {
            base: this.baseURL,
            eiam: this.eiamURL,
          };
          console.log("this.logoutUserAction");
          this.logoutUserAction(logOutUrls);
        }
        this.setLogOutCountdown();
      }, 1000);
    },
  },
};
</script>

<style>
@import url(./assets/css/vendors.css);
@import url(./assets/css/admin4vue.css);
@import url(./assets/css/print.css);
@import url(./assets/css/fabricator.css);
@import url(./assets/css/iwOverdrive.css);
</style>
