import api from "@/api/api-config";
import axios from "axios";
import jwt_decode from "jwt-decode";

const key = "token";

export async function loginUserAxios(login) {
  const { data } = await axios.post(login.tokenEndpoint, login.eiamLoginParams, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  });
  const tokenRefreshOffsetInMs = 10000;
  const tokenPayload = jwt_decode(data.id_token);
  const tokenExpTimeInSec = tokenPayload.exp;
  const tokenExpTimeInMs = tokenExpTimeInSec * 1000;
  sessionStorage.setItem("tokenExpTime", tokenExpTimeInMs);
  sessionStorage.setItem("tokenRefreshTime", tokenExpTimeInMs - tokenRefreshOffsetInMs);
  sessionStorage.setItem("refreshToken", data.refresh_token);
  sessionStorage.setItem("validityDuration", data.expires_in);
  sessionStorage.setItem("id_token", data.id_token);
  return await api.post("Login", { oidctoken: data.id_token });
}

export function getToken() {
  return sessionStorage.getItem(key);
}

export function getTokenExpireTime(tokenPayload) {
  if (tokenPayload) {
    let expireTimeInSec = tokenPayload.exp;
    let expireTimeInMs = expireTimeInSec * 1000;
    return expireTimeInMs;
  }
}

export async function tokenRefresh(refreshProps) {
  return await axios.post(refreshProps.tokenEndpoint, refreshProps.eiamLoginParams, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  });
}

export function logOut() {
  // Unklar ob diese Funktion noch aufgerufen wird / wegweg??
  sessionStorage.clear();
}
