import { createRouter, createWebHistory } from "vue-router";
import PublicViewDE from "@/views/Allgemein/PublicViewDE";
import { authGuard } from "@/auth/auth.guard";
import store from "../store";

const routes = [
  // Hauptseite und Sprachumschaltung
  {
    path: "/",
    name: "Startseite",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: PublicViewDE,
    meta: { requiresAuth: false },
  },
  {
    path: "/FR",
    name: "Französisch",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/PublicViewFR"),
    meta: { requiresAuth: false },
  },
  {
    path: "/IT",
    name: "Italienisch",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/PublicViewIT"),
    meta: { requiresAuth: false },
  },
  {
    path: "/EN",
    name: "Englisch",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/PublicViewEN"),
    meta: { requiresAuth: false },
  },
  {
    path: "/PublicFilterDEKomponenten",
    name: "Bericht Komponenten",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/PublicFilterDEKomponenten"),
    meta: { requiresAuth: false },
  },

  // Hauptmenu
  {
    path: "/Berichte",
    name: "Berichte",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/Berichte"),
    meta: { requiresAuth: true },
  },
  {
    path: "/VorgangNeu",
    name: "Vorgang neu",
    catPath: "/Allgemein/MobilDatenerfassung",
    catName: "Datenerfassung",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/VorgangNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/VorgangSuchen/:id?",
    name: "Vorgang suchen",
    catPath: "/Allgemein/MobilDatenerfassung",
    catName: "Datenerfassung",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/VorgangSuchen"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltKorrekturen",
    name: "Korrekturen",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/UnterhaltKorrekturen"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltAdressen",
    name: "Adressen",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/UnterhaltAdressen"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltAdressenFirmaDetail/:id/:edit?",
    name: "Firma detail",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltAdressen",
    mainName: "Adressen",
    component: () => import("@/views/Menu/UnterhaltAdressenFirmaDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltAdressenFirmaNeu",
    name: "Firma erfassen",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltAdressen",
    mainName: "Adressen",
    component: () => import("@/views/Menu/UnterhaltAdressenFirmaNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltAdressenKontaktNeu",
    name: "Kontakt erfassen",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltAdressen",
    mainName: "Adressen",
    component: () => import("@/views/Menu/UnterhaltAdressenKontaktNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltAdressenKontaktDetail/:id/:edit?",
    name: "Kontakt detail",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltAdressen",
    mainName: "Adressen",
    component: () => import("@/views/Menu/UnterhaltAdressenKontaktDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltArtikeldaten",
    name: "Artikeldaten",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/UnterhaltArtikeldaten"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ZulassungSuchen/:id?",
    name: "Zulassungen",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/ZulassungSuchen"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltBenutzer",
    name: "Benutzer",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/UnterhaltBenutzer"),
    meta: { requiresAuth: true },
  },

  {
    path: "/UnterhaltBenutzerNeu/:id/",
    name: "Benutzer erfassen",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltBenutzer",
    mainName: "Benutzer",
    component: () => import("@/views/Menu/UnterhaltBenutzerNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Anwenderhandbuch",
    name: "Anwenderhandbuch",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Menu/Anwenderhandbuch"),
    meta: { requiresAuth: true },
  },
  {
    path: "/VorgangDetail/:id/:edit?",
    name: "Vorgang detail",
    catPath: "/Allgemein/MobilDatenerfassung",
    catName: "Datenerfassung",
    mainPath: "/VorgangSuchen",
    mainName: "Vorgang suchen",
    component: () => import("@/views/Menu/VorgangDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/PruefungDetail/:id/:nr",
    name: "Pruefung detail",
    catPath: "/Allgemein/MobilDatenerfassung",
    catName: "Datenerfassung",
    mainPath: "/VorgangSuchen",
    mainName: "Vorgang suchen",
    component: () => import("@/views/Menu/PruefungDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ArtikelDetail/:id/:edit?",
    name: "Artikel detail",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltArtikeldaten",
    mainName: "Artikeldaten",
    component: () => import("@/views/Menu/UnterhaltArtikeldatenDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/UnterhaltArtikeldatenNeu",
    name: "Artikel neu",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/UnterhaltArtikeldaten",
    mainName: "Artikeldaten",
    component: () => import("@/views/Menu/UnterhaltArtikeldatenNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ZulassungDetail/:id/:edit/:vorid?",
    name: "Zulassung detail",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/ZulassungSuchen",
    mainName: "Zulassungen",
    component: () => import("@/views/Menu/ZulassungDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/DuebelDetail/:idzul/:nrvor/:idvor?",
    name: "Dübel detail",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/ZulassungSuchen",
    mainName: "Zulassungen",
    component: () => import("@/views/Menu/DuebelDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/DuebelNeu/:idzul/:iddub/:nrvor/:idvor?",
    name: "Dübel neu",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/ZulassungSuchen",
    mainName: "Zulassungen",
    component: () => import("@/views/Menu/DuebelNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/DuebelSubTypNeu/:idzul/:nrvor/:idvor?",
    name: "Dübel Sub Typ neu",
    catPath: "/Allgemein/MobilUnterhalt",
    catName: "Unterhalt",
    mainPath: "/ZulassungSuchen",
    mainName: "Zulassungen",
    component: () => import("@/views/Menu/DuebelSubTypNeu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ProduktionDetail/:id/:nr",
    name: "Produktion detail",
    catPath: "/Allgemein/MobilDatenerfassung",
    catName: "Datenerfassung",
    mainPath: "/VorgangSuchen",
    mainName: "Vorgang suchen",
    component: () => import("@/views/Menu/ProduktionDetail"),
    meta: { requiresAuth: true },
  },

  // Berichte Filter
  {
    path: "/BerichteFilter/FirmenKontakteFilter",
    name: "Firmen und Kontakte",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/FirmenKontakteFilter"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/Komponenten",
    name: "Komponenten",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/Komponenten"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/KomponentenDaten",
    name: "Komponenten Datenübersicht",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/KomponentenDaten"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/KomponentenBestandteile",
    name: "Komponenten Bestandteile",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/KomponentenBestandteile"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/Duebeltyp",
    name: "Dübeltyp",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/Duebeltyp"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/VorgangUebersicht",
    name: "Vorgang Übersicht",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/VorgangUebersicht"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/VorgangUebersichtEinzeln",
    name: "Vorgang Übersicht Einzeln",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/VorgangUebersichtEinzeln"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/VorgangChronKomponente",
    name: "Chronologisch Vorgang Komponente",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/VorgangChronKomponente"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/VorgangChronFirmaArtikel",
    name: "Chronologisch Vorgang Firma/Artikel",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/VorgangChronFirmaArtikel"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/VorgangZulEntscheide",
    name: "Vorgang Zulassungsentscheide",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/VorgangZulEntscheide"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/ProduktionKomponente",
    name: "Produktion Komponente",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/ProduktionKomponente"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/ProduktionKomponenteBestandteile",
    name: "Produktion Komponente Bestandteile",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/ProduktionKomponenteBestandteile"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/ProduktionJahrFirmaArtikel",
    name: "Produktion Jahreszahlen Firma/Artikel",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/ProduktionJahrFirmaArtikel"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/ProduktionEinbaumenge",
    name: "Produktion Einbaumenge",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/ProduktionEinbaumenge"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/PruefAuftrag",
    name: "Prüfauftrag",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/PruefAuftrag"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/PruefBericht",
    name: "Pruefbericht",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/PruefBericht"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/PruefQualitaetslage",
    name: "Prüfungen Qualitätslage",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/PruefQualitaetslage"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/PruefMerkmal",
    name: "Prüfmerkmale",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/PruefMerkmal"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/PruefRichtwerte",
    name: "Richtwerte Komponente",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/PruefRichtwerte"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BerichteFilter/PruefAudit",
    name: "Überwachungsaudit",
    catPath: "/Berichte",
    catName: "Berichte",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/BerichteFilter/PruefAudit"),
    meta: { requiresAuth: true },
  },

  // Allgemein
  {
    path: "/Allgemein/KontaktAdresse",
    name: "Kontakt",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/KontaktAdresse"),
    meta: { requiresAuth: false },
  },

  // Allgemein (Untermenu für Mobile view)
  {
    path: "/Allgemein/MobilDatenerfassung",
    name: "Übersicht Datenerfassung",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/MobilDatenerfassung"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Allgemein/MobilUnterhalt",
    name: "Unterhalt",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/MobilUnterhalt"),
    meta: { requiresAuth: true },
  },

  // Korrekturen CRUD
  {
    path: "/Korrekturen/Firma",
    name: "Firma",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FirmaIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FirmaCreate",
    name: "Firma Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FirmaIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FirmaEdit/:id/",
    name: "Firma Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FirmaIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/KontaktKorrekturen",
    name: "Kontakt Korrekturen",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/KontaktIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/KontaktKorrekturenCreate",
    name: "Kontakt Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/KontaktIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/KontaktKorrekturenEdit/:id/",
    name: "Kontakt Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/KontaktIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FirmaUndKontakt",
    name: "Kontakt und Firma",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FirmaKontaktIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FirmaUndKontaktCreate",
    name: "Kontakt und Firma Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FirmaKontaktIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FirmaUndKontaktEdit/:id/",
    name: "Kontakt und Firma Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FirmaKontaktIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/QMWertungFirma",
    name: "QMWertung Firma",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/QMWertungFirmaIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/QMWertungFirmaCreate",
    name: "QMWertungFirma Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/QMWertungFirmaIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/QMWertungFirmaEdit/:id/",
    name: "QMWertungFirma Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/QMWertungFirmaIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AuditArt",
    name: "AuditArt",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AuditArtIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AuditArtCreate",
    name: "AuditArt Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AuditArtIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AuditArtEdit/:id/",
    name: "AuditArt Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AuditArtIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Ort",
    name: "Ort",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/OrtIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/OrtCreate",
    name: "Ort Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/OrtIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/OrtEdit/:id/",
    name: "Ort Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/OrtIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Artikel",
    name: "Artikel Korrekturen",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelCreate",
    name: "Artikel Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelEdit/:id/",
    name: "Artikel Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelGruppe",
    name: "Artikel Gruppe",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelGruppeIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelGruppeCreate",
    name: "ArtikelGruppe Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelGruppeIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelGruppeEdit/:id/",
    name: "ArtikelGruppe Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelGruppeIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelTyp",
    name: "Artikel Typ",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelTypIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelTypCreate",
    name: "ArtikelTyp Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelTypIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ArtikelTypEdit/:id/",
    name: "ArtikelTyp Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ArtikelTypIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungsModell",
    name: "Zulassungsmodell Korrekturen",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungsModellIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungsModellCreate",
    name: "ZulassungsModell Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungsModellIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungsModellEdit/:id/",
    name: "ZulassungsModell Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungsModellIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenKorrekturen",
    name: "Zulassungen Korrekturen",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenKorrekturenCreate",
    name: "Zulassungen Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenKorrekturenEdit/:id/",
    name: "Zulassungen Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenKat",
    name: "ZulassungenKat",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenKatIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenKatCreate",
    name: "ZulassungenKat Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenKatIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenKatEdit/:id/",
    name: "ZulassungenKat Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenKatIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenStatus",
    name: "Zulassungen Status",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenStatusIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenStatusCreate",
    name: "ZulassungenStatus Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenStatusIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ZulassungenStatusEdit/:id/",
    name: "ZulassungenStatus Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ZulassungenStatusIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Schutzgrad",
    name: "Schutzgrad",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/SchutzgradIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/SchutzgradCreate",
    name: "Schutzgrad Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/SchutzgradIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/SchutzgradEdit/:id/",
    name: "Schutzgrad Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/SchutzgradIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/DuebelSubTyp",
    name: "Duebel Sub Typ",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/DuebelSubTypIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/DuebelSubTypCreate",
    name: "DuebelSubTyp Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/DuebelSubTypIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/DuebelSubTypEdit/:id/",
    name: "DuebelSubTyp Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/DuebelSubTypIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Duebel",
    name: "Duebel",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/DuebelIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/DuebelCreate",
    name: "Duebel Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/DuebelIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/DuebelEdit/:id/",
    name: "Duebel Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/DuebelIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Bestandteil",
    name: "Bestandteil",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilCreate",
    name: "Bestandteil Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilEdit/:id/",
    name: "Bestandteil Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWFreigabe",
    name: "Bestandteil ZW Freigabe",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWFreigabeIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWFreigabeCreate",
    name: "BestandteilZWFreigabe Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWFreigabeIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWFreigabeEdit/:id/",
    name: "BestandteilZWFreigabe Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWFreigabeIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWZulassung",
    name: "Bestandteil ZW Zulassung",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWZulassungIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWZulassungCreate",
    name: "BestandteilZWZulassung Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWZulassungIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWZulassungEdit/:id/",
    name: "BestandteilZWZulassung Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWZulassungIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Vorgang",
    name: "Vorgang",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/VorgangIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/VorgangCreate",
    name: "Vorgang Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/VorgangIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/VorgangEdit/:id/",
    name: "Vorgang Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/VorgangIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Anlass",
    name: "Anlass",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AnlassIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AnlassCreate",
    name: "Anlass Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AnlassIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AnlassEdit/:id/",
    name: "Anlass Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AnlassIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GrundUeberschr",
    name: "Grund der Überschreitung",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GrundUeberschrIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GrundUeberschrCreate",
    name: "GrundUeberschr Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GrundUeberschrIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GrundUeberschrEdit/:id/",
    name: "GrundUeberschr Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GrundUeberschrIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Produktion",
    name: "Produktion",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ProduktionIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ProduktionCreate",
    name: "Produktion Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ProduktionIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ProduktionEdit/:id/",
    name: "Produktion Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ProduktionIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWKontrolle",
    name: "Bestandteil ZW Kontrolle",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWKontrolleIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWKontrolleCreate",
    name: "BestandteilZWKontrolle Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWKontrolleIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BestandteilZWKontrolleEdit/:id/",
    name: "BestandteilZWKontrolle Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BestandteilZWKontrolleIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Pruefung",
    name: "Prüfung",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefungIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefungCreate",
    name: "Pruefung Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefungIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefungEdit/:id/",
    name: "Pruefung Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefungIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Probenummer",
    name: "Probenummer",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ProbenummerIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ProbenummerCreate",
    name: "Probenummer Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ProbenummerIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/ProbenummerEdit/:id/",
    name: "Probenummer Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/ProbenummerIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Pruefergebnis",
    name: "Prüfergebnis",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefergebnisIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefergebnisCreate",
    name: "Pruefergebnis Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefergebnisIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefergebnisEdit/:id/",
    name: "Pruefergebnis Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefergebnisIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefSpezifikation",
    name: "Prüfspezifikation",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefSpezifikationIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefSpezifikationCreate",
    name: "PruefSpezifikation Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefSpezifikationIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefSpezifikationEdit/:id/",
    name: "PruefSpezifikation Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefSpezifikationIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Pruefmerkmal",
    name: "Prüfmerkmal",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefmerkmalIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefmerkmalCreate",
    name: "Pruefmerkmal Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefmerkmalIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefmerkmalEdit/:id/",
    name: "Pruefmerkmal Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefmerkmalIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/Richtwerte",
    name: "Richtwerte",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/RichtwerteIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/RichtwertCreate",
    name: "Richtwerte Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/RichtwerteIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/RichtwertEdit/:id/",
    name: "Richtwerte Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/RichtwerteIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefArt",
    name: "PrüfArt",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefArtIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefArtCreate",
    name: "PruefArt Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefArtIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/PruefArtEdit/:id/",
    name: "PruefArt Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/PruefArtIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FehlerWertung",
    name: "FehlerWertung",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FehlerWertungIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FehlerWertungCreate",
    name: "FehlerWertung Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FehlerWertungIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/FehlerWertungEdit/:id/",
    name: "FehlerWertung Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/FehlerWertungIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerKorrekturen",
    name: "Benutzer Korrekturen",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerCreate",
    name: "Benutzer Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerEdit/:id/",
    name: "Benutzer Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerGruppe",
    name: "Benutzert Gruppe",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerGruppeIndex"),
    meta: { requiresAuth: true },
  },

  {
    path: "/Korrekturen/BenutzerGruppeCreate",
    name: "BenutzerGruppe Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerGruppeIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerGruppeEdit/:id/",
    name: "BenutzerGruppe Korrekturen Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerGruppeIndexEdit"),
    meta: { requiresAuth: true },
  },
  // Gruppe
  {
    path: "/Korrekturen/Gruppe",
    name: "Gruppe",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GruppeIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GruppeCreate",
    name: "Gruppe Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GruppeIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GruppeEdit/:id/",
    name: "Gruppe Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GruppeIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GruppeFunktion",
    name: "Gruppe Funktion",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GruppeFunktionIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GruppeFunktionCreate",
    name: "GruppeFunktion Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GruppeFunktionIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/GruppeFunktionEdit/:id/",
    name: "GruppeFunktion Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/GruppeFunktionIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AntragStatus",
    name: "Antrag Status",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AntragStatusIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AntragStatusCreate",
    name: "AntragStatus Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AntragStatusIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/AntragStatusEdit/:id/",
    name: "AntragStatus Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/AntragStatusIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerAnfrage",
    name: "Benutzer Anfrage",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerAnfrageIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerAnfrageCreate",
    name: "BenutzerAnfrage Korrekturen Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerAnfrageIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/BenutzerAnfrageEdit/:id/",
    name: "BenutzerAnfrage Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/BenutzerAnfrageIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/TextKonstanten",
    name: "Text Konstanten",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/TextKonstantenIndex"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/TextKonstantenCreate",
    name: "TextKonstanten Create",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/TextKonstantenIndexCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Korrekturen/TextKonstantenEdit/:id/",
    name: "TextKonstanten Edit",
    catPath: "/UnterhaltKorrekturen",
    catName: "Unterhalt",
    mainPath: "/UnterhaltKorrekturen",
    mainName: "Korrekturen",
    component: () => import("@/views/Korrekturen/TextKonstantenIndexEdit"),
    meta: { requiresAuth: true },
  },
  {
    path: "/eiam/:code?",
    name: "Login eIAM",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/LoginUserEiam"),
    meta: { requiresAuth: false },
  },
  {
    path: "/logout",
    name: "Logout",
    catPath: "n/a",
    catName: "n/a",
    mainPath: "n/a",
    mainName: "n/a",
    component: () => import("@/views/Allgemein/logout"),
    meta: { requiresAuth: false },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // Sprung nach Anker erlauben sonst nach oben
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    } else {
      return { top: 0 };
    }
  },
});

// Tab Titel im Browser
router.beforeEach((to, from, next) => {
  document.title = `ZKDB - ${to.name}`;
  store.state.activeRoute = to.path;
  authGuard(to, from, next);
});

export default router;
