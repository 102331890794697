const getters = {
  isAuthenticated: (state) => {
    return state.isAuthenticated ? true : false;
  },
  benutzerName: (state) => {
    return state.signInState.benutzerName;
  },
  benutzerId: (state) => {
    return state.signInState.benutzerId;
  },

  // Gruppen Zugehörigkeit
  isAdmin: (state) => {
    return state.signInState.memberOf.includes("Administrator");
  },
  isBenutzer: (state) => {
    return state.signInState.memberOf.includes("Administrator") || state.signInState.memberOf.includes("Benutzer");
  },
  isLeser: (state) => {
    return (
      state.signInState.memberOf.includes("Administrator") ||
      state.signInState.memberOf.includes("Benutzer") ||
      state.signInState.memberOf.includes("Leser")
    );
  },
};
export default getters;
