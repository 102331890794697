import store from "@/store";

export const authGuard = (to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.requiresAuth);
  if (authRequired) {
    if (store.getters["authModule/isAuthenticated"]) {
      next();
      return;
    } else {
      // next("/Allgemein/LoginUser");
    }
  } else {
    next();
  }
};
