
import axios from "axios";
import { interceptorsInit } from "@/api/interceptors";

const debug = process.env.NODE_ENV !== "production";
const baseURL = debug ? "/api" : "/api";
let api = axios.create({ baseURL });
api = interceptorsInit(api);
console.log("api-config");

export default api;


