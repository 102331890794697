<template>
  <section class="nav-services clearfix">
    <h2 class="sr-only">Sprachwahl</h2>
    <nav class="nav-lang">
      <ul>
        <li>
          <router-link
            to="/"
            lang="de"
            title="Deutsch Ausgewählt"
            aria-label="Deutsch Ausgewählt "
            :class="{ active: this.$store.state.activeRoute === '/' }"
            >DE</router-link
          >
        </li>
        <li>
          <router-link
            to="/FR"
            lang="fr"
            title="Français"
            aria-label="Français "
            :class="{ active: this.$store.state.activeRoute === '/FR' }"
            >FR</router-link
          >
        </li>
        <li>
          <router-link
            to="/IT"
            lang="it"
            title="Italiano"
            aria-label="Italiano "
            :class="{ active: this.$store.state.activeRoute === '/IT' }"
            >IT</router-link
          >
        </li>
        <li>
          <router-link to="/EN" lang="en" title="English" aria-label="English " :class="{ active: this.$store.state.activeRoute === '/EN' }"
            >EN</router-link
          >
        </li>
      </ul>
    </nav>
    <nav class="nav-service">
      <ul>
        <li v-if="this.isAuthenticated">
          <small>Angemeldet als {{ this.benutzerName }} </small>
        </li>
        <li v-if="!this.isAuthenticated"><a @click.prevent="loginEiam()" href="#">Login </a></li>
        <li v-if="this.isAuthenticated" @click="this.logoutUserAction({ base: this.baseURL, eiam: this.eiamURL })">
          <a href="#">Logout</a>
        </li>
        <li><router-link to="/Allgemein/KontaktAdresse">Kontakt </router-link></li>
      </ul>
    </nav>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import crypto from "crypto";

export default {
  computed: {
    ...mapGetters("authModule", {
      isAuthenticated: "isAuthenticated",
      benutzerName: "benutzerName",
    }),
    ...mapGetters(["baseURL", "eiamURL", "eiamClientId", "eiamResponseType", "eiamScope"]),
  },

  methods: {
    ...mapActions("authModule", ["logoutUserAction"]),

    // https://auth0.com/docs/get-started/authentication-and-authorization-flow/call-your-api-using-the-authorization-code-flow-with-pkce#create-code-verifier
    // Dependency: Node.js crypto module
    // https://nodejs.org/api/crypto.html#crypto_crypto
    base64URLEncode(str) {
      return str.toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    },

    // Dependency: Node.js crypto module
    // https://nodejs.org/api/crypto.html#crypto_crypto
    sha256(buffer) {
      return crypto.createHash("sha256").update(buffer).digest();
    },

    loginEiam() {
      let verifier = this.base64URLEncode(crypto.randomBytes(32));
      sessionStorage.setItem("verifier", verifier);
      let challenge = this.base64URLEncode(this.sha256(verifier));
      let eiamLoginURL = this.eiamURL + "realms/vbs_vtg-fub-zkdb/protocol/openid-connect/auth?";
      eiamLoginURL = eiamLoginURL + "response_type=" + this.eiamResponseType;
      eiamLoginURL = eiamLoginURL + "&code_challenge=" + challenge;
      eiamLoginURL = eiamLoginURL + "&code_challenge_method=S256";
      eiamLoginURL = eiamLoginURL + "&client_id=" + this.eiamClientId;
      eiamLoginURL = eiamLoginURL + "&redirect_uri=" + encodeURI(this.baseURL) + "eiam";
      eiamLoginURL = eiamLoginURL + "&scope=" + this.eiamScope;
      window.location.href = eiamLoginURL;
    },
  },
};
</script>
