import * as types from "./types";
import jwt_decode from "jwt-decode";

const mutations = {
  [types.LOGIN_USER](state, data) {
    // Wenn es keine ID gibt, gibt es ihn in der DB noch nicht
    if (data.id) {
      state.signInState.id = data.id;
      state.signInState.benutzerName = data.benutzerName;
      state.signInState.memberOf = data.memberOf.toString();
      state.signInState.token = data.token;
      state.isAuthenticated = true;
      // Token wird in sessionStorage des Browsers gespeichert
      sessionStorage.setItem("verifier", "");
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("memberOf", data.memberOf);
      sessionStorage.setItem("benutzerId", data.id);
      sessionStorage.setItem("benutzerName", data.benutzerName);
    }
    // OIDC Daten und Token werden im sessionStorage des Browsers gespeichert
    var oidcToken = jwt_decode(sessionStorage.getItem("id_token"));
    state.signInState.oidcUserExtId = oidcToken.sub.slice(oidcToken.sub.lastIndexOf('\\') + 1);
    state.signInState.oidcGivenName = oidcToken.given_name;
    state.signInState.oidcFamilyName = oidcToken.family_name;
    state.signInState.oidcEmail = oidcToken.email;
    sessionStorage.setItem("oidcUserExtId", oidcToken.sub.slice(oidcToken.sub.lastIndexOf('\\') + 1));
    sessionStorage.setItem("oidcLanguage", oidcToken.language);
    sessionStorage.setItem("oidcGiven_name", oidcToken.given_name);
    sessionStorage.setItem("oidcFamily_name", oidcToken.family_name);
    sessionStorage.setItem("oidcEmail", oidcToken.email);
  },

  [types.LOGOUT_USER](state) {
    state.signInState.id = "";
    state.signInState.benutzerName = "";
    state.signInState.memberOf = "";
    state.signInState.token = "";
    state.isAuthenticated = false;
    // Token wird in sessionStorage des Browsers leer gespeichert/gelöscht
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("memberOf", "");
    sessionStorage.setItem("benutzerId", "");
    sessionStorage.setItem("benutzerName", "");
    // eIAM Daten und Token werden in sessionStorage des Browsers leer gespeichert/gelöscht
    state.signInState.verifier = "";
    state.signInState.oidcToken = "";
    state.signInState.oidcUserExtId = "";
    state.signInState.oidcLanguage = "";
    state.signInState.oidcGiven_name = "";
    state.signInState.oidcFamily_name = "";
    state.signInState.oidcEmail = "";
    sessionStorage.setItem("verifier", "");
    sessionStorage.setItem("id_token", "");
    sessionStorage.setItem("oidcUserExtId", "");
    sessionStorage.setItem("tokenRefreshTime", "");
    sessionStorage.setItem("refreshToken", "");
    sessionStorage.setItem("tokenExpTime", "");
    sessionStorage.setItem("validityDuration", "");
    sessionStorage.setItem("oidcLanguage", "");
    sessionStorage.setItem("oidcGiven_name", "");
    sessionStorage.setItem("oidcFamily_name", "");
    sessionStorage.setItem("oidcEmail", "");
  },

  [types.TOKEN_REFRESH](state, data) {
    const tokenRefreshOffsetInMs = 10000;
    const accessTokenPayload = jwt_decode(data.id_token);
    const tokenExpTimeInSec = accessTokenPayload.exp;
    const tokenExpTimeInMs = tokenExpTimeInSec * 1000;
    sessionStorage.setItem("tokenRefreshTime", tokenExpTimeInMs - tokenRefreshOffsetInMs);
    sessionStorage.setItem("refreshToken", data.refresh_token);
    sessionStorage.setItem("validityDuration", data.expires_in);
    sessionStorage.setItem("id_token", data.id_token);
    state.signInState.token = accessTokenPayload.token;
    // Token wird in sessionStorage des Browsers gespeichert
      sessionStorage.setItem("token", accessTokenPayload.token);
  },
};

export default mutations;
