<template>
  <div class="clearfix">
    <router-link to="/" class="brand hidden-xs">
      <img
        src="@/assets/img/logo-CH.svg"
        onerror="this.onerror=null; this.src='@/assets/img/logo-CH.png'"
        alt="Logo der Schweizerischen Eidgenossenschaft – zur Startseite"
      />
      <h1>Bundesamt für Bevölkerungsschutz BABS</h1>
    </router-link>
  </div>
</template>
