<template>
  <h1 class="page-title">Öffentliche Berichte</h1>
  <p class="lead"></p>
  <div class="row">
    <div class="col-sm-12">
      <h2>Downloads Gesamtlisten (PDF)</h2>
      <ul class="list-unstyled">
        <li>
          <a
            :href="reportURL + 'Zulassungsliste&Format=PDF&Parameters=lang,de'"
            class="icon icon--before icon--pdf"
            target="_blank"
            type="application/pdf"
            >Liste der geprüften und zugelassenen Komponenten im Bereich Zivilschutz <span class="text-dimmed">(PDF)</span></a
          >
        </li>
        <li>
          <a
            :href="reportURL + 'Herstellerliste&Format=PDF&Parameters=lang,de'"
            class="icon icon--before icon--pdf"
            target="_blank"
            type="application/pdf"
            >Liste der Zulassungsinhaber für Komponenten im Bereich Zivilschutz <span class="text-dimmed">(PDF)</span></a
          >
        </li>
      </ul>
      <h4>Zugelassene Dübel/Anker für schocksichere Befestigungen in Schutzbauten</h4>
      <ul class="list-unstyled">
        <li>
          <a
            :href="reportURL + 'Duebel_Z_Liste&Format=PDF&Parameters=lang,de'"
            class="icon icon--before icon--pdf"
            target="_blank"
            type="application/pdf"
            >Liste der zugelassenen Dübel/Anker <span class="text-dimmed">(PDF)</span></a
          >
        </li>
        <li>
          <a
            :href="reportURL + 'Duebel_Liste_sort&Format=PDF&Parameters=lang,de'"
            class="icon icon--before icon--pdf"
            target="_blank"
            type="application/pdf"
            >Datenliste, sortiert nach zulässiger Last <span class="text-dimmed">(PDF)</span></a
          >
        </li>
        <li>
          <a
            :href="reportURL + 'Duebel_Detailliste&Format=PDF&Parameters=lang,de'"
            class="icon icon--before icon--pdf"
            target="_blank"
            type="application/pdf"
            >Detaildaten pro Dübel/Anker Typ <span class="text-dimmed">(PDF)</span></a
          >
        </li>
      </ul>
    </div>
    <p class="lead"></p>
    <div class="col-sm-12">
      <h2>Downloads Teillisten mit Suchfilter</h2>
      <ul class="list-unstyled">
        <li>
          <router-link to="/PublicFilterDEKomponenten" class="icon icon--before icon--doc"
            >Liste der geprüften und zugelassenen Komponenten im Bereich Zivilschutz</router-link
          >
        </li>
      </ul>
    </div>
    <p class="lead"></p>
    <img src="static/zkdb_logo.png" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PublicViewDE",

  components: {},

  computed: {
    ...mapGetters(["reportURL"]),
  },
};
</script>
