<template>
  <div class="container container-main">
    <header>
      <div class="clearfix">
        <TreeCrumb />
        <KontaktSprache />
        <Header />
      </div>
    </header>
    <HauptMenu />
    <BreadCrumb />
    <div class="container-fluid">
      <div class="row">
        <SubMenu class="hidden-xs" v-if="this.isAuthenticated" />
        <div class="col-md-9 col-sm-9" id="content">
          <router-view />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TreeCrumb from "@/components/Allgemein/TreeCrumb";
import KontaktSprache from "@/components/Allgemein/KontaktSprache";
import Header from "@/components/Allgemein/Header";
import HauptMenu from "@/components/Allgemein/HauptMenu";
import BreadCrumb from "@/components/Allgemein/BreadCrumb";
import SubMenu from "@/components/Allgemein/SubMenu";
import Footer from "@/components/Allgemein/Footer";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("authModule", {
      isAuthenticated: "isAuthenticated",
    }),
  },

  components: {
    TreeCrumb,
    KontaktSprache,
    Header,
    HauptMenu,
    BreadCrumb,
    SubMenu,
    Footer,
  },
};
</script>
