<template>
  <div class="col-sm-3">
    <nav class="nav-page-list nav-page-list--facette">
      <router-link class="icon icon--before icon--less" to="/">Startseite</router-link>
      <ul>
        <li>
          <ul>
            <li :class="{ active: $store.state.activeRoute === '/Berichte' }">
              <router-link to="/Berichte">Berichte</router-link>
            </li>
          </ul>
        </li>
        <li>
          <button class="icon icon--before icon--root" data-target="#content-datenerfassung"><b>Datenerfassung</b></button>
          <ul id="content-datenerfassung">
            <li :class="{ active: $store.state.activeRoute === '/VorgangNeu' }">
              <router-link to="/VorgangNeu">Vorgang neu</router-link>
            </li>
            <li :class="{ active: $store.state.activeRoute === '/VorgangSuchen' }">
              <router-link to="/VorgangSuchen">Vorgang suchen</router-link>
            </li>
          </ul>
        </li>
        <li>
          <button class="icon icon--before icon--root" data-target="#content-2"><b>Unterhalt</b></button>
          <ul id="content-2">
            <li v-if="this.isAdmin" :class="{ active: $store.state.activeRoute === '/UnterhaltKorrekturen' }">
              <router-link to="/UnterhaltKorrekturen">Korrekturen</router-link>
            </li>
            <li :class="{ active: $store.state.activeRoute === '/UnterhaltAdressen' }">
              <router-link to="/UnterhaltAdressen">Adressen</router-link>
            </li>
            <li :class="{ active: $store.state.activeRoute === '/UnterhaltArtikeldaten' }">
              <router-link to="/UnterhaltArtikeldaten">Artikeldaten</router-link>
            </li>
            <li :class="{ active: $store.state.activeRoute === '/ZulassungSuchen' }">
              <router-link to="/ZulassungSuchen">Zulassungen</router-link>
            </li>
            <li v-if="this.isAdmin" :class="{ active: $store.state.activeRoute === '/UnterhaltBenutzer' }">
              <router-link to="/UnterhaltBenutzer">Benutzer</router-link>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li :class="{ active: $store.state.activeRoute === '/Anwenderhandbuch' }">
              <router-link to="/Anwenderhandbuch">Handbuch</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "submenu",

  data() {
    return {
      active: null,
    };
  },

  computed: {
    ...mapGetters("authModule", {
      isAdmin: "isAdmin",
    }),
  },
};
</script>
