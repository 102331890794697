import api from "@/api/api-config";

export async function getUiConfig() {
  try {
    const { data } = await api.get(`UiConfig`);
    return data;
  } catch (e) {
    alert("ui-config-services.getUiConfig: Something happened. Please try again.");
  }
}
