import "@/jqueryStartup";
import { createApp, VueElement } from "vue";
import App from "./AppZKDB.vue";
import router from "./router";
import store from "./store";
import { LoadingPlugin } from "vue-loading-overlay";

// Swiss Stylguide
import "@/assets/js/vendors4vue.js";
import "@/assets/js/main4vue.js";
import "@/assets/js/fabricator.min.js";
import "vue-loading-overlay/dist/css/index.css";

createApp(App).use(router).use(store).use(LoadingPlugin, { color: "green" }).mount("#app");
