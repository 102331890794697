<template>
  <div class="nav-main yamm navbar" id="main-navigation">
    <h2 class="sr-only">Navigation</h2>
    <section class="nav-mobile">
      <div class="table-row">
        <div class="nav-mobile-header">
          <div class="table-row">
            <span class="nav-mobile-logo">
              <img
                src="@/assets/img/swiss.svg"
                onerror="this.onerror=null; this.src='@/assets/img/swiss.png'"
                alt="Confederatio Helvetica"
              />
            </span>
            <h1><a href="#">ZKDB</a></h1>
          </div>
        </div>
        <div class="table-cell dropdown">
          <a href="#" class="nav-mobile-menu dropdown-toggle" data-toggle="dropdown"><span class="icon icon--menu"></span></a>
          <div class="drilldown dropdown-menu" role="menu">
            <div class="drilldown-container">
              <nav class="nav-page-list">
                <ul>
                  <li><router-link to="/">Startseite</router-link></li>
                  <li><a @click.prevent="loginEiam()" href="#">Login </a></li>
                  <li v-if="this.isAuthenticated"><router-link to="/Berichte">Berichte</router-link></li>
                  <li v-if="this.isAuthenticated"><router-link to="/Allgemein/MobilDatenerfassung">Datenerfassung</router-link></li>
                  <li v-if="this.isAuthenticated"><router-link to="/Allgemein/MobilUnterhalt">Unterhalt</router-link></li>
                  <li v-if="this.isAuthenticated"><router-link to="/Anwenderhandbuch">Anwenderhandbuch</router-link></li>
                  <li><a href="/Allgemein/KontaktAdresse" class="">Kontakt</a></li>
                  <li v-if="this.isAuthenticated" @click="this.logoutUserAction({ base: this.baseURL, eiam: this.eiamURL })">
                    <a href="/">Logout</a>
                  </li>
                </ul>
                <a href="#" class="yamm-close-bottom"><span class="icon icon--top" aria-hidden="true"></span></a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- The tab navigation -->
    <ul class="nav navbar-nav">
      <li class="dropdown current yamm-fw">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown"
          >Zivilschutz Komponenten Datenbank <span class="sr-only">current page</span></a
        >
        <ul class="dropdown-menu" role="menu">
          <li>
            <div class="yamm-content container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <a href="#" class="yamm-close icon icon--after icon--close pull-right" role="menuitem">Schliessen</a>
                </div>
              </div>
              <div class="row border-flyout">
                <div class="col-sm-6 col-md-4">
                  <h3>
                    <li><router-link to="/">Startseite</router-link></li>
                  </h3>
                </div>
                <div>
                  <a href="#" class="yamm-close-bottom" role="menuitem"><span class="icon icon--top"></span></a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li v-if="this.isAuthenticated" class="dropdown yamm-fw">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown">Schnellzugriff</a>
        <ul class="dropdown-menu" role="menu">
          <li>
            <div class="yamm-content container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <a href="#" class="yamm-close icon icon--after icon--close pull-right" role="menuitem">Schliessen</a>
                </div>
              </div>
              <div class="row border-flyout">
                <div class="col-sm-6 col-md-3">
                  <h3>
                    <router-link to="/Berichte">Berichte</router-link>
                  </h3>
                </div>
                <div class="col-sm-6 col-md-3">
                  <h3>Datenerfassung</h3>
                  <ul role="menu">
                    <li role="presentation">
                      <router-link to="/VorgangNeu" role="menuitem">Vorgang neu</router-link>
                    </li>
                    <li role="presentation">
                      <router-link to="/VorgangSuchen" role="menuitem">Vorgang suchen</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-6 col-md-3">
                  <h3>Unterhalt</h3>
                  <ul>
                    <li v-if="this.isAdmin" role="presentation">
                      <router-link to="/UnterhaltKorrekturen" role="menuitem">Korrekturen</router-link>
                    </li>
                    <li role="presentation">
                      <router-link to="/UnterhaltAdressen" role="menuitem">Adressen</router-link>
                    </li>
                    <li role="presentation">
                      <router-link to="/UnterhaltArtikeldaten" role="menuitem">Artikeldaten</router-link>
                    </li>
                    <li role="presentation">
                      <router-link to="/ZulassungSuchen" role="menuitem">Zulassungen</router-link>
                    </li>
                    <li v-if="this.isAdmin" role="presentation">
                      <router-link to="/UnterhaltBenutzer" role="menuitem">Benutzer</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-6 col-md-3">
                  <h3>
                    <router-link to="/Anwenderhandbuch" role="menuitem">Anwenderhandbuch</router-link>
                  </h3>
                </div>
                <div>
                  <a href="#" class="yamm-close-bottom" role="menuitem"><span class="icon icon--top"></span></a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import crypto from "crypto";

export default {
  name: "mainmenu",

  data() {
    return {};
  },

  computed: {
    ...mapGetters("authModule", {
      isAuthenticated: "isAuthenticated",
      isAdmin: "isAdmin",
    }),
    ...mapGetters(["baseURL", "eiamURL", "eiamClientId", "eiamResponseType", "eiamScope"]),
  },

  methods: {
    ...mapActions("authModule", ["logoutUserAction"]),

    base64URLEncode(str) {
      return str.toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    },

    sha256(buffer) {
      return crypto.createHash("sha256").update(buffer).digest();
    },

    loginEiam() {
      let verifier = this.base64URLEncode(crypto.randomBytes(32));
      sessionStorage.setItem("verifier", verifier);
      let challenge = this.base64URLEncode(this.sha256(verifier));
      let eiamLoginURL = this.eiamURL + "realms/vbs_vtg-fub-zkdb/protocol/openid-connect/auth?";
      eiamLoginURL = eiamLoginURL + "response_type=" + this.eiamResponseType;
      eiamLoginURL = eiamLoginURL + "&code_challenge=" + challenge;
      eiamLoginURL = eiamLoginURL + "&code_challenge_method=S256";
      eiamLoginURL = eiamLoginURL + "&client_id=" + this.eiamClientId;
      eiamLoginURL = eiamLoginURL + "&redirect_uri=" + encodeURI(this.baseURL) + "eiam";
      eiamLoginURL = eiamLoginURL + "&scope=" + this.eiamScope;
      window.location.href = eiamLoginURL;
    },
  },
};
</script>
