import Vuex from "vuex";
import authModule from "./auth";
import router from "../router/index";

const store = new Vuex.Store({
  modules: {
    authModule,
  },

  state: {
    // UI Configuration aus Backend appsettings.json
    uiConfig: {},

    // In Zukunft Einstellungen pro Benutzer speichern
    setting: {
      pageSize: [10, 25, 50, 100],
      pageSizeSelect: 10,
    },

    // Session-Timeout
    token: {
      isValidityPeriodSet: false,
      validityPeriod: 15,
      remainingTokenValidityPeriod: 10,
    },

    notification: {
      isOpen: false,
      isContinuationConfirmed: false,
      routingObj: {
        fullPath: "",
      },
      isLeavingEditMode: false,
      leaveEditMode: false,
      leaveRoute: false,
    },

    userPropsAreSet: false
  },

  mutations: {
    // General
    setUserProps(state) {
      const token = sessionStorage.getItem("token");
      if (token) {
        state.authModule.isAuthenticated = true;
      } else {
        state.authModule.isAuthenticated = false;
      }
      state.authModule.signInState.memberOf = sessionStorage.getItem("memberOf");
      state.authModule.signInState.benutzerName = sessionStorage.getItem("benutzerName");
      if (sessionStorage.getItem("benutzerId")) {
        state.authModule.signInState.benutzerId = JSON.parse(sessionStorage.getItem("benutzerId"));
      }
      if (state.authModule.signInState.memberOf) {
        state.userPropsAreSet = true;
      }
    },

    setNewUserProps(state) {
      state.authModule.signInState.oidcFamilyName = sessionStorage.getItem("oidcFamily_name");
      state.authModule.signInState.oidcGivenName = sessionStorage.getItem("oidcGiven_name");
      state.authModule.signInState.oidcEmail = sessionStorage.getItem("oidcEmail");
      state.authModule.signInState.oidcUserExtId = sessionStorage.getItem("oidcUserExtId");
    },
    
    setUiConfig(state, uiConfig) {
      state.uiConfig = uiConfig;
    },

    // Notification
    notificationHandler(state) {
      state.notification.isOpen = false;
      if (state.notification.isLeavingEditMode) {
        state.notification.isLeavingEditMode = false;
        state.notification.leaveEditMode = true;
      } else {
        state.notification.isContinuationConfirmed = true;
        router.push(state.notification.routingObj.fullPath);
      }
    },
    handleLeaveOfEditMode(state) {
      state.notification.isOpen = true;
      state.notification.isLeavingEditMode = true;
    },
    handleRouteLeaveAbortion(state, to) {
      state.notification.isOpen = true;
      state.notification.routingObj = to;
    },
    handleRouteLeave(state) {
      state.notification.isOpen = false;
      state.notification.isContinuationConfirmed = false;
      state.notification.routingObj.fullPath = "";
    },
    setToTrue(state, varNames) {
      varNames.length == 1 ? (state[varNames[0]] = true) : (state[varNames[0]][varNames[1]] = true);
    },
    setToFalse(state, varNames) {
      varNames.length == 1 ? (state[varNames[0]] = false) : (state[varNames[0]][varNames[1]] = false);
    },
  },

  actions: {},

  getters: {
    // liefert die URL für den Report-Viewer Service
    reportURL: (state) => {
      if (state.uiConfig && state.uiConfig.ReportViewerUrl) {
        return state.uiConfig.ReportViewerUrl;
      } else {
        return "no config available";
      }
    },
    baseURL: (state) => {
      if (state.uiConfig && state.uiConfig.BaseUrl) {
        return state.uiConfig.BaseUrl;
      } else {
        return "no config available";
      }
    },
    eiamURL: (state) => {
      if (state.uiConfig && state.uiConfig.OidcAuthority) {
        return state.uiConfig.OidcAuthority;
      } else {
        return "no config available";
      }
    },
    eiamClientId: (state) => {
      if (state.uiConfig && state.uiConfig.OidcClientId) {
        return state.uiConfig.OidcClientId;
      } else {
        return "no config available";
      }
    },
    eiamResponseType: (state) => {
      if (state.uiConfig && state.uiConfig.OidcResponseType) {
        return state.uiConfig.OidcResponseType;
      } else {
        return "no config available";
      }
    },
    eiamScope: (state) => {
      if (state.uiConfig && state.uiConfig.OidcScope) {
        return state.uiConfig.OidcScope;
      } else {
        return "no config available";
      }
    },
  },
});

export default store;
