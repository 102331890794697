const state = {
    signInState: {
      benutzerId: "",
      benutzerName: "",
      memberOf: ""//,
      // iat: Date.now(),
      // exp: Date.now()
    },
    isAuthenticated: false
  };
  export default state;