<template>
  <div class="container-fluid hidden-xs">
    <div class="row">
      <div class="col-sm-12">
        <h2 id="br1" class="sr-only">Breadcrumbs</h2>
        <ol class="breadcrumb" role="menu" aria-labelledby="br1">
          <li v-if="$route.name !== 'Startseite'">
            <router-link to="/">Startseite</router-link>
            <span class="icon icon--greater"></span>
          </li>

          <li v-if="getRoute({ type: 'catPath' }) !== 'n/a'">
            <router-link :to="getRoute({ type: 'catPath' })">{{ getRoute({ type: "n/a" }).catName }}</router-link
            ><span class="icon icon--greater"></span>
          </li>
          <li v-if="getRoute({ type: 'mainPath' }) !== 'n/a'">
            <router-link :to="getRoute({ type: 'mainPath' })">{{ getRoute({ type: "n/a" }).mainName }}</router-link
            ><span class="icon icon--greater"></span>
          </li>
          <li>
            <router-link :to="$route.path">{{ $route.name }}</router-link
            ><span class="icon icon--greater"></span>
          </li>
          <!-- <li class="active" role="presentation">Vorgang neu</li> -->
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getRoute(path) {
      let pathOrName;

      // Das aktuelle Route-Objekt holen.
      let currentRoute = this.$router.options.routes.find((route) => {
        return route.name === this.$route.name;
      });

      if (currentRoute) {
        // Wenn der Name gebraucht wird.
        if (path.type === "n/a") {
          pathOrName = currentRoute;
          // Wenn der Pfad optionale Kriterien verarbeiten kann, diese ab "/:" wegschneiden...
        } else if (currentRoute[path.type].includes("/:")) {
          pathOrName = currentRoute[path.type].split("/:")[0];
        } else {
          // und sonst einfach den Pfad zurückgeben
          pathOrName = currentRoute[path.type];
        }
      } else {
        // Wenn kein Objekt gefunden wird (um Fehler zu vermeiden).
        pathOrName = "";
      }

      return pathOrName;
    },
  },
};
</script>
